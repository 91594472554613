import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./SignIn.css";

import {
  TextField,
  FormControl,
  InputLabel,
  Button,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import loginPage from "../../assets/login_page.svg";

const SignUp = ({
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
  setSignup,
  setSignin,
  signUp,
}) => {
  // const navigate = useNavigate()

  const [formDetails, updateFormDetails] = useState({
    email: null,
    password: null,
    confirmPassword: null,
  });

  // const [displayPage, setDisplayPage] = useState(false)

  const [error, setError] = useState(true);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    handlePassword();
  }, [formDetails]);

  const handlePassword = () => {
    if (formDetails.password !== null) {
      if (formDetails.password === formDetails.confirmPassword) {
        setError(false);
      } else {
        setError(true);
      }
    }
  };

  const signUpUser = (e) => {
    e.preventDefault();
    signUp(
      formDetails.email,
      formDetails.password,
      formDetails.confirmPassword
    );
  };

  return (
    <div className="signin_background">
      <div className="signin_container">
        <img src={loginPage} alt="Login page" id="loginpage-img" />
        <div className="form-wrapper">
          <div className="log-sign-heading">Sign Up here!</div>
          <TextField
            required
            id="username_input"
            label="Email ID"
            margin="normal"
            style={{ width: "90%" }}
            name="email"
            onChange={(e) => {
              updateFormDetails((prev) => ({
                ...prev,
                [e.target.name]: e.target.value,
              }));
            }}
          />
          <FormControl
            required
            sx={{ m: 1, width: "25ch" }}
            variant="outlined"
            style={{ width: "90%" }}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    id="eye-button"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              name="password"
              onChange={(e) => {
                updateFormDetails((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }));
              }}
            />
          </FormControl>
          <FormControl
            required
            sx={{ m: 1, width: "25ch" }}
            variant="outlined"
            style={{ width: "90%" }}
            error={error}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Confirm Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showConfirmPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                    edge="end"
                    id="eye-button"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Confirm Password"
              name="confirmPassword"
              onChange={(e) => {
                updateFormDetails((prev) => ({
                  ...prev,
                  [e.target.name]: e.target.value,
                }));
              }}
            />
          </FormControl>
          <Button
            onClick={signUpUser}
            id="signin-button"
            style={{ display: error ? "none" : "inline-block" }}
          >
            Sign Up
          </Button>
          <div id="signupbanner">
            Already have an account? Log in{" "}
            <span
              style={{ fontWeight: "600", cursor: "pointer" }}
              onClick={() => {
                setSignup((prev) => !prev);
                setSignin((prev) => !prev);
              }}
            >
              here!
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
