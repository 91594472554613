import './UnderMaintenance.css';
import mt from '../../icons/website-maintenance.png'

function UnderMaintenance(){
    return(
        <div className="under-maintenance">
            <h2>UNDER MAINTENANCE</h2>
            <img src={mt} alt="maintenance" />
            <p>This page is under maintenance. Sorry for the inconvenience caused.</p>
        </div>
        );
}

export default UnderMaintenance;