import React from 'react';
import './Logo.css'; // Import the CSS file

const Logo = () => {
  return (
    <div className="logo-container">
      <span className="letter c">C</span>
      <span className="letter y">Y</span>
      <span className="letter i">I</span>
      <span className="letter e">E</span>
      <span className="letter n">N</span>
      <span className="letter t">T</span>
    </div>
  );
};

export default Logo;
