import "./Footer.css";
import ct from "../../icons/crobotlogo.png";
import {
  FaFacebook,
  FaLinkedinIn,
  FaInstagram,
  FaYoutube,
  FaLink,
  FaMail
} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="flex-box footer_container">
      <div className="bottom">
        <div className="footer_content">
          <p>POWERED BY </p>
          <img src={ct} alt="crobot" className="footer_logo" />
          <p>CROBOT TECHNOLOGIES PVT. LTD.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
