import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Link, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";

// React 18
const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<React.StrictMode>
	<Router>
		<AuthProvider>
			<App />
		</AuthProvider>
	</Router>
</React.StrictMode>);