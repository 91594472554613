import { useEffect } from "react";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ModernDashboard from "../../components/ModernDashboard/ModernDashboard";

import { useAuth } from "../../context/AuthContext";

import './Dashboard.css';

const Dashboard = () => {

    const { currentUser, getUserDetails, roboList, getLinkedRobots } = useAuth();

    useEffect(() => {
        const funcEx = async () => {
            await getUserDetails(currentUser.id, currentUser.token)
        }
        funcEx()
    }, [])

    if (currentUser !== null) {
        return (
            <>
                <ModernDashboard currentUser={currentUser} getUserDetails={getUserDetails} roboList={roboList} getLinkedRobots={getLinkedRobots} />
            </>

        );
    }
    else {
        return (
            <>
                <Header present={window.location.pathname} />
                <h2 style={{ marginTop: '15vh', minHeight: '60vh', color: 'var(--supplementary-color)' }}>No access without login</h2>
                <Footer />
            </>

        );
    }
}

export default Dashboard;