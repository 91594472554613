import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "./SignUp_Success.css"

import mail_sent from "../../icons/sent-mail.svg"
import { useAuth } from "../../context/AuthContext";

function SignupSuccess() {

	// const navigate = useNavigate()
	// const [refreshing, setRefreshing] = useState(false)

	const { currentUser } = useAuth()


    return(
        <div id='confirmation'>
        	<h2> Thank You for signing up with Crobot Technologies!</h2>
        	<div id="mail_icon">
				<img src={mail_sent} id="sent_mail" />
			</div>
			<div style={{width:"fit-content", margin:"5% auto 0"}}>
				A mail has been sent to registered Email ID<br/>
				Please go to your inbox and verify your account.<br/><br/>
				Didn't recieve the email? <a id="resend" href="">Resend Email</a>
			</div>
   		</div>
    );
}

export default SignupSuccess;