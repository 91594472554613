import React from "react";

const DashSchedules = () => {
  return (
    <>
      <div className="schedules">
        <h1 className="schedules-title">schedules</h1>
        <iframe
          className="calendar-iframe"
          src="https://outlook.live.com/owa/calendar/00000000-0000-0000-0000-000000000000/0976906a-c485-4b59-b599-38edc0148efa/cid-C9CF88294854F9E8/index.html"
          width="80%"
          height="80%"
          frameborder="0"
        ></iframe>
      </div>
    </>
  );
};

export default DashSchedules;
