import React from 'react'

const DashMeetCard = ({meeting, onCardClick}) => {

    const handleCardClick = () => {
        onCardClick(meeting.meetingUrl); // Call the parent's function with the meeting URL
    };

    return (
        <div className='scheduled-meet-card' onClick={handleCardClick} style={{ border: '1px solid #ccc', padding: '10px', margin: '10px', cursor: 'pointer' }}>
            <h2>{meeting.subject}</h2>
            <p>Scheduled Time: {new Date(meeting.startTime).toLocaleString()}</p>
        </div>
    )
}

export default DashMeetCard