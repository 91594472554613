import { useState } from "react";

import Header from "../../components/Header/Header";

import './Home.css'
import Logo from "../../components/Logo/Logo"; 
import { Link } from "react-router-dom";

function Home() {
	
	const [transparent, setTransparent] = useState(true)

	window.addEventListener('scroll', () => {
		if (((window.scrollY / window.innerHeight) * 100) > 90) {
			setTransparent(false)
		}
		else {
			setTransparent(true)
		}
	})


	return (
		<div className="center-container">
			<Logo />
			<button className="sign-up-button"><Link to='/signin'>Sign In</Link></button>
		</div>
	);
}
export default Home;
