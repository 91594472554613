import React, { useState, useEffect, useMemo } from "react";
import { useKeyPressEvent } from "react-use";
import {
  FaArrowUp,
  FaArrowLeft,
  FaArrowRight,
  FaArrowDown,
  FaStop,
  FaCamera,
} from "react-icons/fa";
import green_led from "../../icons/green-led.png"
import battery_icon from "../../icons/battery-icon.png"

import "../../pages/RoboDashboard/roboDashboard.css";
import "./DashCustom.css";
import { useAuth } from "../../context/AuthContext";
import { useOutletContext } from "react-router-dom";

import operate from "../../assets/operate_page.svg";
import axios from "axios";

import {
  AzureCommunicationTokenCredential,
  CommunicationUserIdentifier,
} from "@azure/communication-common";
import {
  CallComposite,
  fromFlatCommunicationIdentifier,
  useAzureCommunicationCallAdapter,
} from "@azure/communication-react";
import DashMeetCard from "./DashMeetCard";
import Battery from "./Battery.jsx";
import Indicator from "./indicator.jsx";

const Control = ({
  onClose,
  onLeft,
  onRight,
  onUp,
  onDown,
  cameraUp,
  cameraDown,
  ws,
}) => {
  useKeyPressEvent("Escape", () =>
    ws.send(
      JSON.stringify({
        action: "message",
        data:
          "stop" +
          "_" +
          new Date().getUTCHours().toString() +
          ":" +
          new Date().getUTCMinutes().toString() +
          ":" +
          new Date().getUTCSeconds().toString(),
      })
    )
  );
  useKeyPressEvent("b", () =>
    ws.send(
      JSON.stringify({
        action: "message",
        data:
          "stop" +
          "_" +
          new Date().getUTCHours().toString() +
          ":" +
          new Date().getUTCMinutes().toString() +
          ":" +
          new Date().getUTCSeconds().toString(),
      })
    )
  );
  useKeyPressEvent("a", () =>
    ws.send(
      JSON.stringify({
        action: "message",
        data:
          "left" +
          "_" +
          new Date().getUTCHours().toString() +
          ":" +
          new Date().getUTCMinutes().toString() +
          ":" +
          new Date().getUTCSeconds().toString(),
      })
    )
  );
  useKeyPressEvent("d", () =>
    ws.send(
      JSON.stringify({
        action: "message",
        data:
          "right" +
          "_" +
          new Date().getUTCHours().toString() +
          ":" +
          new Date().getUTCMinutes().toString() +
          ":" +
          new Date().getUTCSeconds().toString(),
      })
    )
  );
  useKeyPressEvent("w", () =>
    ws.send(
      JSON.stringify({
        action: "message",
        data:
          "forward" +
          "_" +
          new Date().getUTCHours().toString() +
          ":" +
          new Date().getUTCMinutes().toString() +
          ":" +
          new Date().getUTCSeconds().toString(),
      })
    )
  );
  useKeyPressEvent("s", () =>
    ws.send(
      JSON.stringify({
        action: "message",
        data:
          "backward" +
          "_" +
          new Date().getUTCHours().toString() +
          ":" +
          new Date().getUTCMinutes().toString() +
          ":" +
          new Date().getUTCSeconds().toString(),
      })
    )
  );
  // useKeyPressEvent("q", cameraUp);
  // useKeyPressEvent("e", cameraDown);

  return (
    <div className="video-control-keypads">
      <div className="camera-control-control">
        <button onClick={() => ws.send(JSON.stringify({
          action: "message",
          data: "cameraUp" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
        }))}><FaArrowUp /></button>
        <FaCamera />
        <button onClick={() => ws.send(JSON.stringify({
          action: "message",
          data: "cameraDown" + "_" + new Date().getUTCHours().toString() + ":" + new Date().getUTCMinutes().toString() + ":" + new Date().getUTCSeconds().toString(),
        }))}><FaArrowDown /></button>
      </div>
      <div className="video-control-control">
        <button
          onClick={() =>
            ws.send(
              JSON.stringify({
                action: "message",
                data:
                  "stop" +
                  "_" +
                  new Date().getUTCHours().toString() +
                  ":" +
                  new Date().getUTCMinutes().toString() +
                  ":" +
                  new Date().getUTCSeconds().toString(),
              })
            )
          }
        >
          <FaStop />
        </button>
        <button
          onClick={() =>
            ws.send(
              JSON.stringify({
                action: "message",
                data:
                  "forward" +
                  "_" +
                  new Date().getUTCHours().toString() +
                  ":" +
                  new Date().getUTCMinutes().toString() +
                  ":" +
                  new Date().getUTCSeconds().toString(),
              })
            )
          }
        >
          <FaArrowUp />
        </button>
        <button
          onClick={() =>
            ws.send(
              JSON.stringify({
                action: "message",
                data:
                  "left" +
                  "_" +
                  new Date().getUTCHours().toString() +
                  ":" +
                  new Date().getUTCMinutes().toString() +
                  ":" +
                  new Date().getUTCSeconds().toString(),
              })
            )
          }
        >
          <FaArrowLeft />
        </button>
        <button
          onClick={() =>
            ws.send(
              JSON.stringify({
                action: "message",
                data:
                  "right" +
                  "_" +
                  new Date().getUTCHours().toString() +
                  ":" +
                  new Date().getUTCMinutes().toString() +
                  ":" +
                  new Date().getUTCSeconds().toString(),
              })
            )
          }
        >
          <FaArrowRight />
        </button>
        <button
          onClick={() =>
            ws.send(
              JSON.stringify({
                action: "message",
                data:
                  "backward" +
                  "_" +
                  new Date().getUTCHours().toString() +
                  ":" +
                  new Date().getUTCMinutes().toString() +
                  ":" +
                  new Date().getUTCSeconds().toString(),
              })
            )
          }
        >
          <FaArrowDown />
        </button>
      </div>
    </div>
  );
};

const DashOperate = () => {
  const ws = new WebSocket(
    "wss://7udtkx6tq5.execute-api.us-east-1.amazonaws.com/production?name=mahaarun"
  );

  const apiCallRight = {
    action: "message",
    data:
      "right" +
      "_" +
      new Date().getUTCHours().toString() +
      ":" +
      new Date().getUTCMinutes().toString() +
      ":" +
      new Date().getUTCSeconds().toString(),
  };

  const apiCallLeft = {
    action: "message",
    data:
      "left" +
      "_" +
      new Date().getUTCHours().toString() +
      ":" +
      new Date().getUTCMinutes().toString() +
      ":" +
      new Date().getUTCSeconds().toString(),
  };

  const apiCallForward = {
    action: "message",
    data:
      "forward" +
      "_" +
      new Date().getUTCHours().toString() +
      ":" +
      new Date().getUTCMinutes().toString() +
      ":" +
      new Date().getUTCSeconds().toString(),
  };

  const apiCallBackward = {
    action: "message",
    data:
      "backward" +
      "_" +
      new Date().getUTCHours().toString() +
      ":" +
      new Date().getUTCMinutes().toString() +
      ":" +
      new Date().getUTCSeconds().toString(),
  };

  const apiCallStop = {
    action: "message",
    data:
      "stop" +
      "_" +
      new Date().getUTCHours().toString() +
      ":" +
      new Date().getUTCMinutes().toString() +
      ":" +
      new Date().getUTCSeconds().toString(),
  };

  const apiCallConnect = {
    action: "message",
    data:
      "connect" +
      "_" +
      new Date().getUTCHours().toString() +
      ":" +
      new Date().getUTCMinutes().toString() +
      ":" +
      new Date().getUTCSeconds().toString(),
  };

  const apiCallCameraUp = {
    action: "message",
    data:
      "cameraUp" +
      "_" +
      new Date().getUTCHours().toString() +
      ":" +
      new Date().getUTCMinutes().toString() +
      ":" +
      new Date().getUTCSeconds().toString(),
  };
  const apiCallCameraDown = {
    action: "message",
    data:
      "cameraDown" +
      "_" +
      new Date().getUTCHours().toString() +
      ":" +
      new Date().getUTCMinutes().toString() +
      ":" +
      new Date().getUTCSeconds().toString(),
  };

  ws.onopen = (event) => {
    // ws.send(JSON.stringify(apiCall));
    // console.log("Connected to server");
  };

  function sendMeetLinkToBot(meetLink){
    const meetData = {
      action: "meetLink",
      data : meetLink
    }
    ws.send(JSON.stringify(meetData));
  }

  ws.onmessage = function (event) {
    const json = JSON.parse(event.data);
    try {
      if ((json.event = "data")) {
        // setBids(json.data.bids.slice(0, 5));
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const [isConnected, setConnected] = useState(false);
  const [liveData, setLiveData] = useState("");
  const [roboId, setRoboId] = useState(null);
  const [chargeLevel, setChargeLevel] = useState(80);

  // graph related use state
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  const [meetLink, setMeetLink] = useState(null);
  const [scheduledMeets, setScheduledMeets] = useState(null);
  const displayName = "Bot Interface";
  const credential = useMemo(() => {
    if (token) {
      return new AzureCommunicationTokenCredential(token);
    }
    return;
  }, [token]);

  const callAdapterArgs = useMemo(() => {
    if (userId && credential && displayName && meetLink) {
      return {
        userId: fromFlatCommunicationIdentifier(userId),
        displayName,
        credential,
        locator: { meetingLink: meetLink },
      };
    }
    return {};
  }, [userId, credential, displayName, meetLink]);

  const callAdapter = useAzureCommunicationCallAdapter(callAdapterArgs);

  const {
    currentUser,
    roboList,
    getLinkedRobots,
    msUserId,
    msAccessToken,
    msLogin,
    msAcsToken,
    msAcsUserId,
  } = useAuth();
  const { fetchedDetails } = useOutletContext();

  // useEffect(() => {
  //   console.log(liveData, roboId);
  //   getLinkedRobots(currentUser.id)
  // });

  useEffect(() => {
    const funcEx = async () => {
      try {
        // console.log(`Current user is`, currentUser);
        //await authenticateMSAndGetTokens();
        await getLinkedRobots(currentUser.id);
      } catch (error) {
        // console.error("Error in funcEx:", error);
      }
    };
    funcEx();
  }, []);

  const changeData = (e) => {
    const inputData = e.target.value;
    setLiveData(inputData);
  };

  const handleChange = (e) => {
    setLiveData(e.target.value);
  };

  // graph related function
  async function authenticateMSAndGetTokens() {
    if (!msAccessToken && !msAcsUserId) {
      await msLogin();
    }
    // axios.post(`${process.env.REACT_APP_API}/api/graph/meet`)
    //   .then((res) => {
    //     const data = res.data.meetLink
    //     console.log("Meeting data :" + data);
    //     setMeetLink(res.data.meetLink)
    //   })
    // setMeetLink(
    //   "https://teams.microsoft.com/l/meetup-join/19%3ameeting_YTdhZTVlMzQtYjEwOS00YzQzLTkzNzQtNzAzNDE3ODc5MjM3%40thread.v2/0?context=%7b%22Tid%22%3a%220b837c6d-6e0e-4ecb-bf34-d2f9b7a479f1%22%2c%22Oid%22%3a%225f820cb4-5b34-4c3d-8d75-03c37474cd91%22%7d"
    // );
  }

  async function getCurrentDateUTC(){
    const date = new Date(); // Get the current date and time in UTC

    // Convert UTC to IST (UTC + 5:30)
    const istOffset = 5 * 60 + 30; // 5 hours and 30 minutes in minutes
    const istDate = new Date(date.getTime() + istOffset * 60 * 1000); // Add offset in milliseconds

    // Format the date to 'YYYY-MM-DD'
    const year = istDate.getUTCFullYear();
    const month = String(istDate.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(istDate.getUTCDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

  async function fetchScheduledMeets() {
    var curDate = await getCurrentDateUTC();
    if (msAccessToken && msUserId) {
      await axios
        .get(`${process.env.REACT_APP_API}/api/graph/scheduledMeets`, {
          headers: { Authorization: `Bearer ${currentUser.token}` },
          params: {
            userId: msUserId,
            msAccessToken: msAccessToken,
            startDate:curDate
          },
        })
        .then((res) => {
          if (res.data.success === 1) {
            setScheduledMeets(res.data.scheduledMeets);
          }
        });
    }
  }

  // Update the selected meeting URL when Meeting Card is clicked
  const handleCardClick = (meetingUrl) => {
    setMeetLink(meetingUrl);
    sendMeetLinkToBot(meetingUrl);
  };

  // Callcomposite listeners
  const onMeetVideoCallStarted = () => {};
  const onMeetVideoCallEnded = () => {};

  useEffect(() => {
    if (callAdapter) {
      if (callAdapter) {
        const call = callAdapter.getState().call;
        if (call) {
          // Listen for call status changes
          call.on("stateChanged", () => {
            if (call.state === "Disconnected") {
              onMeetVideoCallEnded();
            } else if (call.state === "Connected") {
            }
          });

          // Cleanup listener on component unmount
          return () => {
            call.off("stateChanged", onMeetVideoCallEnded);
          };
        }
      }
    }
  }, [callAdapter]);

  const getObstacleStatus = () => {
  }

  const getChargeLevel = () => {
  }

  return (
    <div className="row-center-even Robo">
      {
        msAccessToken !== null ? (
          currentUser.subscriptionStatus !== 0 ? (
            !isConnected ? (
              <>
                <div className="fl-col-c-c dash-operate-container">
                  <div className="robocontrol-heading">Operate your robot</div>
                  <div className="fl-col-c-c dash-operate-operation">
                    {!scheduledMeets && (
                      <div className="dash-buttons">
                        <button className="dash-operate-button">
                          Autonomous Navigation
                        </button>
                        <button
                          onClick={(e) => {
                            fetchScheduledMeets();
                          }}
                          className="dash-operate-button"
                        >
                          Telepresence
                        </button>
                      </div>
                    )}
                    {scheduledMeets &&
                      !meetLink &&
                      scheduledMeets.map((meet, index) => (
                        <>
                          <DashMeetCard
                            key={index}
                            meeting={meet}
                            onCardClick={handleCardClick}
                          />
                        </>
                      ))}
                    {/* {
                      scheduledMeets && !meetLink ? (
                        scheduledMeets.map((meet, index)=>(
                          <>
                            <DashMeetCard key={index} meeting={meet} onCardClick={handleCardClick}/>
                          </>
                        ))
                      ) : (
                        <button onClick={(e) => { fetchScheduledMeets() }}>Get Scheduled Meets</button>
                      )
                    } */}
                    {meetLink && (
                      <>
                        <img src={operate} alt="Operate bot" id="operate-img" />
                        <div className="fl-col-c-c dash-operate-list-container">
                          <select
                            className="dash-operate-list-dropdown"
                            onChange={handleChange}
                          >
                            <option value={""}> -- Select a robot --</option>
                            {roboList.map((el) => {
                              // console.log(el);
                              const data = el.roboid;
                              return (
                                <option
                                  value={`${data}`}
                                  className="robocontrol-robots"
                                >
                                  {data}
                                </option>
                              );
                            })}
                          </select>
                          <div className="data-submit">
                            {liveData !== "" && (
                              <button
                                type="submit"
                                onClick={(e) => {
                                  ws.send(JSON.stringify(apiCallConnect));
                                  e.preventDefault();
                                  setRoboId(liveData);
                                  setConnected(true);
                                  setUserId(msAcsUserId);
                                  setToken(msAcsToken);
                                }}
                              >
                                Connect!
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* <div className="row-center-even robocontrol-data-field">
                  <div className="data-input">
                      <input
                        type="text"
                        name=""
                        id="roboData"
                        onChange={changeData}
                      />
                    </div>
                  <div className="data-submit">
                    {liveData !== "" && <button
                      type="submit"
                      onClick={(e) => {
                        ws.send(JSON.stringify(apiCall4));
                        e.preventDefault();
                        setRoboId(liveData);
                        setConnected(true);
                      }}
                    >
                      Connect!
                    </button>}
                  </div>
                </div>*/}
                </div>
              </>
            ) : (
                <div className="fl-col-c-c video-control-container dash-operate-video-container">
                <div className="video-control-meet">
                  {callAdapter && (
                    <div className="teams-meet-container">
                      <div className="teams-meet-header">
                        <h1>UBot Video Stream</h1>
                        <div className="utils">
                            <Indicator obstacleStatus={getObstacleStatus} />
                            <Battery chargeLevel={getChargeLevel} />
                        </div>
                      </div>
                      <div className="wrapper">
                        <CallComposite
                          adapter={callAdapter}
                          token={token}
                          userId={userId}
                        />
                      </div>
                    </div>
                  )}
    
                  {/* <iframe
                    allow="camera; microphone; fullscreen; speaker; display-capture"
                    src="https://crobottech.yourvideo.live/626d23f1f183f8570398a4f9">
                  </iframe>*/}
                  <Control
                    onClose={() => ws.send(JSON.stringify(apiCallStop))}
                    onLeft={() => ws.send(JSON.stringify(apiCallLeft))}
                    onRight={() => ws.send(JSON.stringify(apiCallRight))}
                    onUp={() => ws.send(JSON.stringify(apiCallForward))}
                    onDown={() => ws.send(JSON.stringify(apiCallBackward))}
                    cameraUp={() => ws.send(JSON.stringify(apiCallCameraUp))}
                    cameraDown={() => ws.send(JSON.stringify(apiCallCameraDown))}
                    ws={ws}
                  />
                </div>
              </div>
            )
          ) : (
            <div style={{ fontSize: "1.3rem" }}>
              <span style={{ color: "red" }}>No active subscription</span> plans,
              please purchase one to operate the robot!
            </div>
          )
        ):
        (
          <button className="authenticate-ms-button" onClick={authenticateMSAndGetTokens}>Authenticate Microsoft</button>
        )
      }
    </div>
  );
};
{
  /* <Modal onClose={() => ws.send(JSON.stringify(apiCall3))} />
  <Modal onLeft={() => ws.send(JSON.stringify(apiCall1))} />
  <Modal onRight={() => ws.send(JSON.stringify(apiCall))} />
  <Modal onUp={() => ws.send(JSON.stringify(apiCall2))} />
  <Modal onDown={() => ws.send(JSON.stringify(apiCall3))} /> */
}

export default DashOperate;

// const Modal = function ({ onClose, onLeft, onRight, onUp, onDown, onEnter }) {
// 	useKeyPressEvent("Escape", onClose);
// 	useKeyPressEvent("a", onLeft);
// 	useKeyPressEvent("d", onRight);
// 	useKeyPressEvent("w", onUp);
// 	useKeyPressEvent("s", onDown);

// 	return (
// 		// <ModalBackdrop>
// 		// 	<ModalContent>
// 		// 		<>
// 		// 			<button onClick={onClose}>Stop</button>
// 		// 			<button onClick={onLeft}>Left</button>
// 		// 			<button onClick={onRight}>Right</button>
// 		// 			<button onClick={onUp}>Up</button>
// 		// 			<button onClick={onDown}>Down</button>
// 		// 		</>
// 		// 	</ModalContent>
// 		// </ModalBackdrop>
// 		<>Control goes here</>
// 	);
// };

// function App() {
// 	//give an initial state so that the data won't be undefined at start
// 	// const [bids, setBids] = useState([0]);

// 	const ws = new WebSocket(
// 		"wss://7udtkx6tq5.execute-api.us-east-1.amazonaws.com/production?name=mahaarun"
// 	);

// 	const apiCall = {
// 		action: "message",
// 		data: "right",
// 	};

// 	ws.onopen = (event) => {
// 		ws.send(JSON.stringify(apiCall));
// 		console.log("Connected to server");
// 	};

// 	ws.onmessage = function (event) {
// 		const json = JSON.parse(event.data);
// 		console.log(json);
// 		try {
// 			if ((json.event = "data")) {
// 				// setBids(json.data.bids.slice(0, 5));
// 			}
// 		} catch (err) {
// 			console.log(err);
// 		}
// 	};
// 	//map the first 5 bids
// 	// const firstBids = bids.map((item) => {
// 	// 	return (
// 	// 		<div>
// 	// 			<p> {item}</p>
// 	// 		</div>
// 	// 	);
// 	// });

// 	return <div>Helloworld</div>;
// }

// export default App;
