import React, { useState } from "react";
import "../DashboardComp/Utils.css";

const Battery = ({charge}) => {
  const chargeLevel = charge || null; /*useContext(chargeLevel)*/

  // Determine the color based on charge level
  const getBatteryColor = () => {
    if (chargeLevel > 50) return "rgb(124,223,52)";
    if (chargeLevel > 25) return "orange";
    return "red";
  };

  return (
    <div className="battery-container">
      {chargeLevel !== null ? (<div className="battery">
        <div
          className="battery-fill"
          style={{
            width: `${chargeLevel}%`,
            backgroundColor: getBatteryColor(),
          }}
        ></div>
        <p className="battery-percentage">{`${chargeLevel}%`}</p>
        <div className="battery-cap"></div>
      </div>) : (<div className="battery">
          <div className="battery-cap"></div>
          <div className="no-battery"></div>
      </div>)}
    </div>
  );
};

export default Battery;
