import React from 'react';
import './Utils.css'; // Optional if you'd like to separate styles

const IndicatorButton = () => {
  const obstacle = true // useContext(obstacleStatus);
  const buttonStyle = {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: !obstacle ? 'green' : 'red',
    boxShadow: !obstacle
    ? '0 0 5px rgba(0, 255, 0, 0.5), 0 0 10px rgba(0, 255, 0, 0.3), 0 0 20px rgba(0, 255, 0, 0.2)'
    : '0 0 5px rgba(255, 0, 0, 0.5), 0 0 10px rgba(255, 0, 0, 0.3), 0 0 20px rgba(255, 0, 0, 0.2)'
  };

  return <div className="indicator-button" style={buttonStyle}></div>;
};

export default IndicatorButton;
